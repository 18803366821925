import React from "react"
import logoMain from "../../images/logo_main.png"
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll"
import Navbar from "../navbar/navbar"

const Hero = () => {
  return (
    <div>
      <section className="hero is-medium is-fullheight">
        <div className="hero-head">
          <Navbar />
        </div>

        <div className="hero-body align-flex-start">
          <div className="container ">
            <img className="dd-hero-image" src={logoMain} alt="Logo" />
          </div>
        </div>
        <div className="columns is-centered">
          <div className="column is-centered-in-grid">
            <ScrollLink
              className="icon is-large has-text-black animate__animated animate__slideOutDown animate__delay-2s clickable"
              to="realisaties"
              smooth={true}
            >
              <i className="fas fa-2x fa-chevron-down is-opaque-75"></i>
            </ScrollLink>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Hero
