import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import classNames from "classnames"
import React, { FunctionComponent, KeyboardEvent, useState } from "react"
import useComponentVisible from "../../hooks/use-component-visible/use-component-visible"
import "./card.scss"

export type CardProps = {
  title: string
  description: any
  categories: string[]
  images: string[]
}

export const Card: FunctionComponent<CardProps> = ({
  title,
  description,
  categories,
  images,
}) => {
  const [selectedImage, setSelectedImage] = useState<number | undefined>(
    undefined
  )
  const modalVisibility = useComponentVisible(false)
  const imageModalVisibility = useComponentVisible(false, () => {
    setSelectedImage(undefined)
    modalVisibility.setIsLocked(false)
  })

  const openImageModal = (selectedImage: number) => {
    setSelectedImage(selectedImage)
    modalVisibility.setIsLocked(true)
    imageModalVisibility.setIsComponentVisible(true)
  }

  const closeImageModal = () => {
    setSelectedImage(undefined)
    modalVisibility.setIsLocked(false)
    imageModalVisibility.setIsComponentVisible(false)
  }

  const nextImage = () => {
    if (selectedImage === undefined) {
      return
    }

    if (selectedImage + 1 === images.length) {
      setSelectedImage(0)
      return
    }

    setSelectedImage(selectedImage + 1)
  }

  const previousImage = () => {
    if (selectedImage === undefined) {
      return
    }

    if (selectedImage === 0) {
      setSelectedImage(images.length - 1)
      return
    }

    setSelectedImage(selectedImage - 1)
  }

  const onKeyDown = (keyEvent: KeyboardEvent<HTMLDivElement>) => {
    if (keyEvent.key === "ArrowRight") {
      nextImage()
    } else if (keyEvent.key === "ArrowLeft") {
      previousImage()
    }
  }

  return (
    <>
      <div
        className="card clickable"
        onClick={() => modalVisibility.setIsComponentVisible(true)}
      >
        <div className="card-image">
          <figure className="image ">
            <img src={images[0]} alt="Placeholder image" />
          </figure>
        </div>
        <div className="card-content">
          <p className="title">{title}</p>
          <div className="content">
            {/* <Dotdotdot clamp={3}>{description}</Dotdotdot> */}
          </div>

          <div className="tags">
            {categories.map(category => (
              <span key={category} className="tag is-primary">
                {category}
              </span>
            ))}
          </div>
        </div>
      </div>

      <div
        className={classNames("modal", {
          "is-active": modalVisibility.isComponentVisible,
          "is-clipped": modalVisibility.isComponentVisible,
        })}
      >
        <div className="modal-background"></div>
        <div className="modal-card" ref={modalVisibility.ref}>
          <header className="modal-card-head">
            <p className="modal-card-title">{title}</p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => modalVisibility.setIsComponentVisible(false)}
            ></button>
          </header>
          <section className="modal-card-body">
            <h2 className="subtitle">Beschrijving</h2>
            <div className="content">
              {documentToReactComponents(description)}
            </div>
            <h2 className="subtitle">Afbeeldingen</h2>
            <div className="columns is-multiline">
              {images.map((image, index) => (
                <div
                  key={title + "detail" + index}
                  className="column is-one-quarter-desktop is-half-tablet"
                >
                  <figure
                    className="image clickable"
                    onClick={() => openImageModal(index)}
                  >
                    <img src={image} />
                  </figure>
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>

      <div
        className={classNames("modal", {
          "is-active": imageModalVisibility.isComponentVisible,
          "is-clipped": imageModalVisibility.isComponentVisible,
        })}
        ref={ref => {
          if (ref) {
            ref.focus()
          }
        }}
        tabIndex={-1}
        onKeyDown={keyEvent => onKeyDown(keyEvent)}
      >
        <div className="modal-background"></div>
        <div
          className="modal-content gallery-container is-huge is-image"
          ref={imageModalVisibility.ref}
        >
          <img className="fade" src={images[selectedImage!]} />
          <div className="gallery-icon gallery-icon-previous">
            <span
              className="icon is-large has-text-grey-dark clickable"
              onClick={() => previousImage()}
            >
              <i className="fas fa-2x fa-chevron-left"></i>
            </span>
          </div>
          <div className="gallery-icon gallery-icon-next">
            <span
              className="icon is-large has-text-grey-dark clickable"
              onClick={() => nextImage()}
            >
              <i className="fas fa-2x fa-chevron-right"></i>
            </span>
          </div>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={() => closeImageModal()}
        ></button>
      </div>
    </>
  )
}
