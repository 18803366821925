import classNames from "classnames"
import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import { Card } from "../components/card/card"
import Hero from "../components/hero/hero"
import SEO from "../components/seo"

type CardItem = {
  title: string
  description: any
  categories: Category[]
  images: string[]
}

enum Category {
  Zonnepanelen = "Zonnepanelen",
  Branders = "Branders",
  Stookolie = "Stookolie",
  ServiceOnderhoud = "Service & Onderhoud",
}

const IndexPage = () => {
  const cardItems: CardItem[] = useStaticQuery(
    graphql`
      query {
        allContentfulRealisation(sort: { fields: createdAt, order: DESC }) {
          edges {
            node {
              id
              categories
              images {
                title
                fluid {
                  src
                }
              }
              title
              description {
                raw
              }
            }
          }
        }
      }
    `
  ).allContentfulRealisation.edges.map(({ node }: any) => ({
    title: node.title,
    categories: node.categories,
    description: JSON.parse(node.description.raw),
    images: node.images.map((image: any) => image.fluid.src),
  }))

  const categories = [
    Category.Zonnepanelen,
    Category.Branders,
    Category.Stookolie,
    Category.ServiceOnderhoud,
  ]
  const [selectedCategories, setSelectedCategories] = useState<string[]>([])

  const isSelected = (category: string) => {
    return selectedCategories.includes(category)
  }

  const toggleSelected = (category: string) => {
    if (isSelected(category)) {
      setSelectedCategories(selectedCategories.filter(cat => cat !== category))
      return
    }

    setSelectedCategories([...selectedCategories, category])
  }

  const isCategorySelected = (cardItem: CardItem) =>
    selectedCategories.length === 0 ||
    selectedCategories.some(category => cardItem.categories.includes(category))

  return (
    <>
      <SEO
        title="Home"
        lang="nl"
        meta={[]}
        description="Landingspagina DD Service"
      />
      <Hero />
      <div className="container mt-5">
        <h1 id="realisaties" className="title is-1">
          Realisaties
        </h1>
        <div className="tags">
          {categories.map(category => (
            <span
              key={category}
              className={classNames(
                "tag",
                { "is-primary": isSelected(category) },
                "clickable"
              )}
              onClick={() => toggleSelected(category)}
            >
              {category}
            </span>
          ))}
        </div>
        <div className="columns is-multiline">
          {cardItems
            .filter(item => isCategorySelected(item))
            .map((item, index) => (
              <div key={index} className="column is-one-third">
                <Card
                  title={item.title}
                  description={item.description}
                  categories={item.categories}
                  images={item.images}
                />
              </div>
            ))}
        </div>
      </div>
    </>
  )
}

export default IndexPage
